@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BIG JOHN';
  src: url("/static/media/BIG JOHN.676a8ca7.otf") format('opentype');
}
html {
  font-size: 62.5%;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace,monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.skip-link {
  position: absolute;
  left: -1000px;
  top: 5px;
  z-index: 999;
  background: white;
  color: black;
}

.skip-link:focus {
  left: 0;
}

.visuallyHidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0);
  overflow: hidden;
}

.fadeIn {
  animation: fadeIn 3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::selection {
  background-color: turquoise;
}

:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #3c3438;
  line-height: 22px;
}

.page {
  min-height: calc(100vh - 50px - 255px);
}

img {
  display: block;
  width: 100%;
}

h2,
p {
  margin: 0.6em 0;
}

h2 {
  color: #3c3438;
}

p {
  color: #66371b;
}

.p--bold {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #3c3438;
}

.link {
  border-bottom: 1px solid #fbd4da;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.3s ease-in;
}

.link:hover, .link:focus, .link:active {
  border-bottom: 1px solid #66371b;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  font-family: "Montserrat";
  color: #3c3438;
  border: none;
  margin: 0;
  background: none;
  cursor: pointer;
}

button:hover, button:focus {
  background: #fff1a1;
}

button.active {
  background-color: #fff1a1;
}

.btn {
  font-weight: 500;
  border-radius: 3px;
  border: 1px solid #9e5135;
  padding: 0.3em 0.5em;
  background: #ffecef;
  font-size: 14px;
  font-size: 1.4rem;
}

.btn:hover, .btn:focus {
  background: #fff1a1;
}

.btn.active {
  background-color: #fff1a1;
}

input,
label {
  font-size: 30px;
  font-size: 3rem;
}

select,
input,
textarea {
  border: 1px solid #9e5135;
  border-radius: 3px;
}

select:focus,
input:focus,
textarea:focus {
  background: #fff1a1;
}

.header,
.home,
.footer {
  transition: all 0.3s ease-in;
}

.hide {
  display: none;
}

.flying-label-item {
  position: relative;
}

.flying-label-item__input, .flying-label-item__label {
  border-radius: 3px;
  font-size: 12px;
  font-size: 1.2rem;
  transition: all 0.3s ease-in;
}

.flying-label-item__input.disable, .flying-label-item__label.disable {
  background: #c8c8c8;
  text-decoration: line-through;
}

.flying-label-item__label {
  display: flex;
  border-left: 1px solid #9e5135;
  border-right: 1px solid #9e5135;
  border-radius: 3px 0 0 3px;
  width: auto;
  height: 23px;
  padding: 0 5px;
  align-items: center;
  position: absolute;
  top: 6px;
  right: calc(100%);
  transform: translateX(calc(100%));
  background: #ffffff;
  white-space: nowrap;
}

.flying-label-item__input {
  position: relative;
  margin: 5px 0;
  width: 100%;
  padding: 4px 6px;
  background: #ffffff;
}

.flying-label-item__input:focus, .flying-label-item__input:valid {
  outline: none;
}

.flying-label-item__input:focus + label, .flying-label-item__input:valid + label {
  font-size: 12px;
  font-size: 1.2rem;
  border-radius: 0 3px 3px 0;
  border-right: 1px solid transparent;
  right: 1px;
  background: #66371b;
  color: #ffecef;
  transform: translateX(0);
}

.flying-label-item__input:valid + label {
  background: #c2ff67;
  color: #3c3438;
}

.flying-label-item__input:invalid + label {
  background: #fbd4da;
  color: #3c3438;
}

.flying-label-item__input--coupon:valid + label {
  right: calc(100%);
  transform: translateX(calc(100%));
  background: #ffffff;
  border-right: 1px solid #9e5135;
  border-radius: 0;
}

.flying-label-item__input--coupon:focus + label {
  right: 1px;
  border-right: 1px solid transparent;
  transform: translateX(0);
}

.flying-label-item__input--coupon.valid + label {
  right: 1px;
  border-right: 1px solid transparent;
  transform: translateX(0);
  background: #c2ff67;
  color: #3c3438;
}

fieldset {
  border: none;
}

.hidden {
  display: none;
}

.wrapper {
  width: 92%;
  max-width: 900px;
  margin: 0 auto;
}

.pickup__location {
  display: block;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 900px) {
  body {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 24px;
  }
}

.overlay--loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000000;
}

.overlay--loading ~ * {
  opacity: 0;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 1.5s;
}

.loading, .loading::before, .loading::after {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #9e5135;
  animation: blinking 0.5s ease-in-out infinite alternate;
}

.loading::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(-100% - 5px);
  width: 30px;
}

.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
  width: 30px;
  animation-delay: 2.5s;
}

@keyframes blinking {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(251, 212, 218, 0.9);
  height: 50px;
}

.header__p {
  margin: 0;
  padding: 3px 0;
  line-height: 1.3;
}

.header__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
}

.header__img {
  width: auto;
}

.header__img-container {
  width: 22px;
}

.header__che {
  width: 60px;
}

.header__cart {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 3px;
  width: 32px;
  height: 32px;
}

.header__cart__count {
  line-height: 20px;
  display: inline-block;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background: red;
  color: #fff;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 90vh;
  left: -50vw;
  transform: translateX(50%);
  transition: 0.3s ease-in;
  opacity: 0;
}

.header__cart__count.active {
  top: 4px;
  left: 0;
  opacity: 1;
  transform: translateX(0);
}

.header__top-message {
  width: 100%;
  padding: 2px 0;
  position: fixed;
  top: 50px;
  text-align: center;
  background: #fff1a1;
  z-index: 3;
}

.header__top-message__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__top-message__btn {
  position: absolute;
  right: 0;
}

.nav__hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  height: 18px;
  max-width: 120px;
  min-width: 30px;
  transition: 0.2s ease-in;
}

.nav__hamburger__line {
  align-self: flex-end;
  width: 30px;
  height: 2px;
  background: #66371b;
  transition: all 0.3s ease-in;
}

.nav__hamburger__line--top {
  transform-origin: center;
}

.nav__hamburger__line--top.animate {
  border: 1px solid transparent;
  border-radius: 0;
  background-color: #ffffff;
  transform: translateY(7px) rotate(405deg);
}

.nav__hamburger__line--mid {
  background-color: #9e5135;
}

.nav__hamburger__line--mid.animate {
  opacity: 0;
}

.nav__hamburger__line--bot {
  transform-origin: center;
}

.nav__hamburger__line--bot.animate {
  border: 1px solid transparent;
  border-radius: 0;
  background-color: #ffffff;
  transform: translateY(-7px) rotate(-405deg);
}

.nav__hamburger:active {
  transform: translate(5px, 5px);
}

.nav__hamburger:focus, .nav__hamburger:hover {
  cursor: pointer;
  background: none;
}

.nav__hamburger:focus .nav__hamburger__line--mid, .nav__hamburger:hover .nav__hamburger__line--mid {
  height: 8px;
  background: #ffffff;
}

.nav__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -200%;
  width: 100%;
  height: 100%;
  background: #3c3438;
  transition: all 0.5s ease-in-out;
}

.nav__links li {
  display: grid;
  justify-items: start;
  height: 10%;
  width: 0;
  border-bottom: 1px solid #665e63;
  transition: all 0.8s ease-in;
}

.nav__links li:first-of-type {
  border-top: 1px solid #665e63;
}

.nav__links li:nth-child(2) {
  transition: all 0.9s ease-in;
}

.nav__links li:nth-child(3) {
  transition: all 1s ease-in;
}

.nav__links li:nth-child(4) {
  transition: all 1.1s ease-in;
}

.nav__links li:nth-child(5) {
  transition: all 1.2s ease-in;
}

.nav__links li.animate {
  width: 100%;
}

.nav__links__item {
  font-size: 20px;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #ffffff;
  align-self: center;
  position: relative;
  display: block;
  border: none;
  padding: 15px 0 15px 25px;
  width: 100%;
  transition: all 0.5s ease-in;
  cursor: pointer;
}

.nav__links__item:hover, .nav__links__item:focus {
  border: none;
  color: #fbd4da;
}

.nav__links__item::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  top: initial;
  bottom: 8px;
  left: 0;
  z-index: -1;
  border-bottom: 1px solid transparent;
  width: 0;
  transition: all 0.3s ease-in;
}

.nav__links__item:hover::after {
  border-bottom: 1px solid #ffffff;
  width: calc(100% + 2em);
}

.nav__links.animate {
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(60, 52, 56, 0.9);
}

@media (min-width: 900px) {
  .nav__links__item {
    padding-left: 20%;
  }
}

.footer {
  position: relative;
  height: 255px;
  padding-top: 30px;
  background: #faf1ec;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 20px;
}

.footer__links {
  margin-bottom: 8px;
  padding-right: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 5px;
}

.footer__email {
  cursor: pointer;
  white-space: nowrap;
}

.footer__delivery {
  padding-right: 2em;
}

.footer__pickup {
  margin-bottom: 40px;
}

.footer__credit__text {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 8px;
  font-size: 0.8rem;
  text-align: center;
}

.footer .wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (min-width: 620px) {
  .footer {
    height: auto;
  }
}

@media (min-width: 900px) {
  .footer {
    padding-top: 30px;
    background: #faf1ec;
  }
  .footer__pickup, .footer__delivery {
    margin-top: 0;
  }
}

.cart {
  min-height: calc(100vh - 50px - 255px);
}

.cart__p--coupon-msg {
  margin-top: 0;
}

.cart__h2 {
  margin-bottom: 5px;
}

.cart__h3--date, .cart__h3--total {
  position: relative;
  z-index: 1;
}

.cart__h3--date::after, .cart__h3--total::after {
  content: "";
  width: calc(100% + 10px);
  height: calc(100% + 4px);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
  background: #c59579;
}

.cart__h3--total {
  margin-top: 40px;
}

.cart__h3--date {
  margin-bottom: 0;
}

.cart__h3--product {
  display: inline-block;
}

.cart__h4 {
  margin: 0;
}

.cart__btn--edit {
  display: inline-block;
  border-color: #fbd4da;
  margin-left: 1em;
  padding: 0 0.5em;
  background: transparent;
}

.cart__btn--confirm {
  margin-right: 0.6em;
}

.cart__free {
  position: sticky;
  top: 45px;
  z-index: 2;
  padding-top: 5px;
  background: #fff1a1;
}

.cart__free__p {
  text-align: center;
}

.cart__date {
  margin-bottom: 30px;
}

.cart__product {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 0.6em;
  margin-bottom: 10px;
}

.cart__product__box {
  border-radius: 0.2em;
  border: 1px solid #efd4c4;
  padding: 0.6em;
}

.cart__product__flavour {
  display: grid;
  grid-template-columns: minmax(0, 200px) min-content;
  border-bottom: 1px solid #66371b;
}

.cart__product__flavour:last-child:not(:first-of-type) {
  border: transparent;
}

.cart__product__flavour__name, .cart__product__flavour__amount {
  align-self: flex-end;
}

.cart__grid--info-options {
  display: grid;
  grid-gap: 3.6em;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
}

.cart__exit {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 18px 0;
  background: rgba(251, 212, 218, 0.9);
}

.delivery-options__btn {
  font-size: 14px;
  font-size: 1.4rem;
}

.delivery-options__btn--delivery {
  background: none;
  border: 1px solid #66371b;
  padding: 0.1em 0.3em;
}

.delivery-options__btn--delivery:nth-of-type(1) {
  margin-left: auto;
}

.delivery-options__btn--delivery:nth-of-type(2) {
  margin-left: 0.3em;
}

.delivery-options__btn--delivery.active {
  font-weight: 700;
}

.delivery-options__delivery-address {
  font-size: 12px;
  font-size: 1.2rem;
  width: 100%;
  min-height: 40px;
  resize: vertical;
}

.delivery-options__choice {
  margin-top: 10px;
}

.delivery-options__control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 19px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
}

.delivery-options__control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.delivery-options__control-indicator {
  position: absolute;
  top: 5px;
  left: 0;
  height: 16px;
  width: 16px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: undefinedpx;
}

.delivery-options__control:hover input ~ .delivery-options__control-indicator,
.delivery-options__control input:focus ~ .delivery-options__control-indicator {
  background: #cccccc;
}

.delivery-options__control input:checked ~ .delivery-options__control-indicator {
  background: #66371b;
}

.delivery-options__control:hover input:not([disabled]):checked ~ .delivery-options__control-indicator,
.delivery-options__control input:checked:focus ~ .delivery-options__control-indicator {
  background: #66371b;
}

.delivery-options__control input:disabled ~ .delivery-options__control-indicator {
  background: #e6e6e6;
  opacity: 1;
  pointer-events: none;
}

.delivery-options__control-indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.delivery-options__control input:checked ~ .delivery-options__control-indicator:after {
  display: block;
}

.delivery-options__control-radio .delivery-options__control-indicator {
  border-radius: 35%;
}

.delivery-options__control-radio .delivery-options__control-indicator:after {
  left: 5px;
  top: 5px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #ffffff;
  transition: background 250ms;
}

.delivery-options__control-radio input:disabled ~ .delivery-options__control-indicator:after {
  background: #7b7b7b;
}

.delivery-options__control-radio .delivery-options__control-indicator::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #fff1a1;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

.delivery-options__control-radio input + .delivery-options__control-indicator::before {
  animation: s-ripple 250ms ease-out;
}

.delivery-options__control-radio input:checked + .delivery-options__control-indicator::before {
  animation-name: s-ripple-dup;
}

@keyframes s-ripple {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0.01;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@media (min-width: 900px) {
  .delivery-options {
    margin-top: 10px;
  }
}

.contact-info__input {
  width: 100%;
}

@media (min-width: 900px) {
  .cart__h3--date::after, .cart__h3--total::after {
    content: "";
    width: calc(100% + 12px);
    height: calc(100% + 8px);
  }
  .cart__free {
    padding-bottom: 5px;
  }
}

.flavour-info__image-container {
  margin: 0 auto;
  position: relative;
  clip-path: inset(0);
  z-index: -1;
  height: 400px;
  width: 100%;
  max-height: 100vw;
}

.flavour-info__image-container img {
  width: 100%;
  max-width: 400px;
  position: fixed;
  top: 50px;
  left: 50%;
  object-fit: cover;
  transform: translateX(-50%);
  z-index: -1;
}

.flavour-info__p {
  margin-top: 0;
  margin-bottom: 0;
}

.flavour-info__p--bold {
  font-weight: 700;
}

.flavour-info__btn {
  display: inline-block;
  margin-top: 10px;
}

@media (min-width: 900px) {
  .flavour-info .wrapper {
    display: flex;
    align-items: center;
    margin: auto auto;
    min-height: calc(100vh - 50px - 255px);
    padding: 2em 0;
  }
  .flavour-info__p {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 24px;
  }
  .flavour-info__image-container {
    width: 400px;
  }
  .flavour-info__image-container img {
    position: static;
    transform: translateX(0);
  }
  .flavour-info__grid {
    display: grid;
    grid-template-columns: fit-content 1fr 1fr;
    grid-gap: 1.6em;
  }
  .flavour-info__description {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2 / span 2;
  }
  .flavour-info .flavours--flavour-info.flavours {
    margin-top: 0;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1 / span 3;
  }
  .flavour-info .flavours--flavour-info.flavours:nth-of-type(2) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1 / span 3;
  }
  .flavour-info .flavours--flavour-info.flavours .flavours__grid {
    margin-bottom: 0;
  }
  .flavour-info .flavours--flavour-info.flavours.flavours--all .flavours__grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
}

.home__h2--believe {
  display: inline;
  line-height: 24px;
}

.home__p {
  margin: 0;
}

.home__p--sub-title {
  display: inline-block;
  margin-bottom: 3px;
  font-size: 12px;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 600;
  color: #3c3438;
}

.home__away {
  margin-bottom: 30px;
  max-width: 540px;
}

.home__believe {
  margin-bottom: 30px;
  max-width: 540px;
  padding-top: 20px;
}

.home__products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-columns: 1fr;
  grid-gap: 10px;
  margin-top: 8px;
  margin-bottom: 30px;
}

.home__top-message {
  width: 100%;
  padding: 2px 0;
  position: fixed;
  top: 50px;
  text-align: center;
  background: #fff1a1;
  z-index: 3;
}

.home__top-message__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home__top-message__btn {
  position: absolute;
  right: 0;
}

.shop {
  min-height: calc(100vh - 50px - 255px);
}

.shop__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.shop__grid-item__info {
  text-align: center;
}

.shop__grid-item__oos {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  background-color: rgba(102, 94, 99, 0.5);
}

.shop__grid-item__oos__text {
  width: 100%;
  text-align: center;
  background: #c8c8c8;
}

.shop__grid-item__image {
  transition: all .3s ease-in;
}

.shop__grid-item__name {
  border-bottom: 1px solid transparent;
  margin-top: 5px;
  margin-bottom: 0;
  transition: all 0.3s ease-in;
}

.shop__grid-item__price {
  margin: 0;
}

.shop__grid-item:hover .shop__grid-item__name {
  border-bottom: 1px solid #9e5135;
}

.shop__grid-item:hover .shop__grid-item__image {
  transform: scale(1.5);
}

.shop-item__image-container {
  position: relative;
}

.order-form__p {
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
}

.order-form__p--quantity {
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 0.6em;
}

.order-form__p--bulk {
  margin-bottom: 8px;
  line-height: 22px;
}

.order-form__p--set {
  margin-top: 0.6em;
  font-size: 12px;
  font-size: 1.2rem;
}

.order-form__name {
  margin-top: 0;
  margin-bottom: 12px;
}

.order-form__h5 {
  margin: 0;
  font-size: 16px;
  font-size: 1.6rem;
}

.order-form__btns {
  padding-top: 0.6em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 0.6em;
}

.order-form__btn {
  font-size: 16px;
  font-size: 1.6rem;
}

.order-form__btn--week {
  background: none;
  border: 1px solid #66371b;
  padding: 0.1em 0.3em;
}

.order-form__btn--week.active {
  font-weight: 700;
}

.order-form__btn--atc {
  margin: 0 auto;
}

.order-form__image-container {
  margin: 0 auto;
  position: relative;
  clip-path: inset(0);
  width: 100%;
  height: 400px;
  max-height: 100vw;
  z-index: -1;
}

.order-form__image-container img {
  max-width: 500px;
  width: 100%;
  position: fixed;
  top: 50px + 20px;
  left: 50%;
  object-fit: cover;
  transform: translateX(-50%);
  z-index: -1;
}

.order-form__week-selection {
  padding-top: 10px;
  padding-bottom: 6px;
  background-color: #ffecef;
}

.order-form__quantity {
  padding: 10px 0;
  position: sticky;
  top: 50px;
  z-index: 1;
  background: #ffecef;
}

.order-form__quantity__wrapper {
  display: flex;
}

.order-form__package {
  display: grid;
  grid-gap: 10px;
  margin: 25px 0;
}

.order-form__atc {
  padding: 8px 0;
  position: sticky;
  bottom: 0;
  background: #ffecef;
}

.order-form__atc__wrapper {
  display: flex;
  flex-direction: column;
}

.order-form__atc__msg {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  color: #cc0000;
}

.order-form__other {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}

.order-form__other .shop__grid {
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.order-form__other .shop__grid-item {
  position: relative;
}

.order-form__other .shop__grid-item__name {
  margin: 0;
  width: 100%;
  padding: 0.3em 0.5em;
  position: absolute;
  bottom: 0;
  background-color: #eed4cb;
  transition: all 0.3s ease-in;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #3c3438;
  text-align: center;
}

.order-form__other .shop__grid-item:hover .shop__grid-item__name {
  border-bottom: 1px solid transparent;
  background: #fff1a1;
}

@media (min-width: 900px) {
  .order-form {
    min-height: calc(100vh - 50px - 255px);
  }
  .order-form__image-container img {
    top: 0;
  }
  .order-form__p--bulk {
    margin-bottom: 8px;
  }
  .order-form__boxes {
    display: grid;
    padding: 1.6em 0;
    grid-gap: 1.6em;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1.6em), 1fr));
  }
  .order-form__package {
    margin: 0;
  }
  .order-form__instruction {
    white-space: nowrap;
  }
}

.flavour-picker {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flavour-picker__label {
  margin-right: 2px;
  border-bottom: 1px solid #9e5135;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
}

.flavour-picker__container {
  display: flex;
  align-self: center;
  width: min-content;
  height: min-content;
  border-radius: 3px;
  border: 1px solid #9e5135;
}

.flavour-picker__amount {
  border-radius: 0;
  border: 0;
  border-left: 1px solid #9e5135;
  border-right: 1px solid #9e5135;
  width: 2em;
  padding-top: 2px;
  box-shadow: none;
  font-size: 15px;
  font-size: 1.5rem;
  text-align: center;
  -moz-appearance: textfield;
}

.flavour-picker__amount::-webkit-outer-spin-button, .flavour-picker__amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flavour-picker__btn {
  background: none;
  border: 0;
  width: 1.6em;
  padding: 0;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 900px) {
  .shop {
    margin-bottom: 30px;
  }
}

.products__item-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.products__btn-container {
  padding: 0.6em 0;
  position: sticky;
  bottom: 0;
  background: rgba(251, 212, 218, 0.8);
}

.products__btn-container .wrapper {
  display: flex;
}

.products__btn {
  margin: 0 auto;
}

.products__item {
  display: flex;
  flex-direction: column;
}

.products__item__image-container {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in;
}

.products__item__image-container:hover .products__item__image {
  transform: scale(1.5);
}

.products__item__image-container:hover + .products__item__info .products__item__name {
  border-bottom: 1px solid #9e5135;
}

.products__item__oos {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  background-color: rgba(102, 94, 99, 0.5);
}

.products__item__oos__text {
  width: 100%;
  text-align: center;
  background: #c8c8c8;
}

.products__item__info {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.products__item__image {
  transition: all 0.3s ease-in;
}

.products__item__name {
  border-bottom: 1px solid transparent;
  margin-bottom: 5px;
  transition: all 0.3s ease-in;
}

@media (min-width: 900px) {
  .products__item-container {
    grid-gap: 30px;
  }
  .products__item__description {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 24px;
  }
}

.add-to-cart {
  margin-top: 1em;
  background-color: #ffecef;
  font-size: 12px;
  font-size: 1.2rem;
  color: #3c3438;
}

.add-to-cart__container {
  padding: 0.8em 0;
}

.add-to-cart__container:nth-child(odd) {
  background-color: #fbd4da;
}

.add-to-cart__info-container {
  margin-left: .6em;
}

.add-to-cart__wrapper {
  display: flex;
}

.add-to-cart__wrapper > * {
  margin-right: 0.4em;
}

.add-to-cart__wrapper :last-child {
  margin-right: 0;
}

.cart-input {
  align-self: center;
  display: flex;
  width: min-content;
  height: min-content;
  border-radius: 0.3em;
  border: 1px solid #9e5135;
}

.cart-input__amount {
  border-radius: 0;
  border: 0;
  border-left: 1px solid #9e5135;
  border-right: 1px solid #9e5135;
  width: 2em;
  padding-top: 2px;
  box-shadow: none;
  font-size: 15px;
  font-size: 1.5rem;
  text-align: center;
  -moz-appearance: textfield;
}

.cart-input__amount::-webkit-outer-spin-button, .cart-input__amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-input__btn {
  background: none;
  border: 0;
  width: 1.6em;
  padding: 0;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
}

.flavours__h2 {
  margin-bottom: 0px;
}

.flavours__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
  margin-top: 8px;
  margin-bottom: 30px;
}

.flavours__grid-item {
  position: relative;
  display: block;
  align-self: start;
  overflow: hidden;
}

.flavours__grid-item__name {
  margin: 0;
  width: 100%;
  padding: 0.3em 0.5em;
  position: absolute;
  bottom: 0;
  background-color: #eed4cb;
  transition: all 0.3s ease-in;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #3c3438;
  text-align: center;
}

.flavours__grid-item__image {
  transition: all 0.3s ease-in;
}

.flavours__grid-item:hover .flavours__grid-item__image {
  transform: scale(1.5);
}

.flavours__grid-item:hover .flavours__grid-item__name {
  background-color: #fff1a1;
}

.flavour-info .flavours.flavours--all .flavours__grid {
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
}

.flavour-info .flavours--flavour-info {
  margin-top: 30px;
}

.flavour-info .flavours__grid {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.flavour-info .flavours__grid-item {
  line-height: 1.2em;
}

@media (min-width: 900px) {
  .flavours__grid-item__name {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 20px;
  }
}

.shop-item {
  position: relative;
}

.shop-item__image-container {
  overflow: hidden;
}

.shop-item__image {
  transition: all 0.3s ease-in;
}

.shop-item:hover .shop-item__name {
  background-color: #fff1a1;
}

.shop-item:hover .shop-item__image {
  transform: scale(1.5);
}

.shop-item__name {
  margin: 0;
  width: 100%;
  padding: 0.3em 0.5em;
  position: absolute;
  bottom: 0;
  background-color: rgba(238, 212, 203, 0.9);
  transition: all 0.3s ease-in;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #3c3438;
  text-align: center;
}

.shop-item__info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.shop-item__price {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 2;
  text-align: center;
  border: 1px solid #66371b;
}

.shop-item__price__bundle:first-child {
  border-right: 1px solid #9e5135;
}

.shop-item__week--this {
  grid-column: 1;
  grid-row: 1;
}

.shop-item__week--next {
  justify-self: right;
  text-align: right;
}

@media (min-width: 900px) {
  .shop-item__name {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.thankyou {
  min-height: calc(100vh - 50px - 255px);
}

.embla {
  position: relative;
  background-color: #ffffff;
  max-width: 1980px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(rgba(251, 212, 218, 0.9), #fff);
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  padding-left: 10px;
  min-width: 80%;
  position: relative;
}

.embla__slide__inner {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.embla__slide__parallax {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: rgba(102, 55, 27, 0.7);
  padding: 0;
}

.embla__button:active, .embla__button:hover, .embla__button:focus {
  background-color: transparent;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}

